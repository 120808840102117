function Participant(name, conferenceRoom) {
	this.name = name;
	this.id = name;
	this.video = document.createElement('video');
	this.video.id = 'video-' + this.name;
	this.video.autoplay = true;
	this.video.controls = false;

	this.remoteVideo = document.createElement('video');
	this.remoteVideo.id = 'remote-video-' + this.name;
	this.remoteVideo.autoplay = true;
	this.remoteVideo.controls = false;

	var conferenceRoom = conferenceRoom;

	var rtcPeer;

	this.offerToReceiveVideo = function (error, offerSdp, wp) {
		if (error) return console.error("sdp offer error")
		console.log('Invoking SDP offer callback function');
		var msg = {
			id: "receiveVideoFrom",
			sender: name,
			sdpOffer: offerSdp
		};

		conferenceRoom.sendMessage(msg);
	}

	this.onIceCandidate = function (candidate, wp) {
		//console.log("Local candidate" + JSON.stringify(candidate));

		var message = {
			id: 'onIceCandidate',
			candidate: candidate,
			name: name
		};

		conferenceRoom.sendMessage(message);
	}

	Object.defineProperty(this, 'rtcPeer', {writable: true});

	this.dispose = function () {
		//console.log('Disposing participant ' + this.name);

		this.rtcPeer.dispose();
	};
}
