function ConferenceRoom(client) {
	var ws = null;
	var client = client;
	var name;
	var room;
	var self = this;
	var participants = {};

	window.onbeforeunload = function () {
		ws.close();
	};

	this.register = function(name_, room_) {
		name = name_;
		room = room_;

		ws = new WebSocket(client.settings.webSocket);

		ws.onopen = function(){
			var message = {
				id: 'joinRoom',
				name: name,
				room: room,
			}

			self.sendMessage(message);
		};

		ws.onmessage = function (message) {
			var parsedMessage = JSON.parse(message.data);

			switch (parsedMessage.id) {
				case 'existingParticipants':
					onExistingParticipants(parsedMessage);
					break;
				case 'newParticipantArrived':
					onNewParticipant(parsedMessage);
					break;
				case 'participantLeft':
					onParticipantLeft(parsedMessage);
					break;
				case 'receiveVideoAnswer':
					receiveVideoResponse(parsedMessage);
					break;
				case 'iceCandidate':
					participants[parsedMessage.name].rtcPeer.addIceCandidate(parsedMessage.candidate, function (error) {
						if (error) {
							console.error("Error adding candidate: " + error);
							return;
						}
					});
					break;
				case 'sendToAll':
					client.handleCustomMessage(parsedMessage.value);

					break;
				default:
					console.error('Unrecognized message', parsedMessage);
			}
		}
	}

	function onNewParticipant(request) {
		receiveVideo(request.name);
	}

	function receiveVideoResponse(result) {
		participants[result.name].rtcPeer.processAnswer(result.sdpAnswer, function (error) {
			if (error) return console.error(error);
		});
	}

	function onExistingParticipants(msg) {
		var constraints = {
			audio: true,
			video: {
				mandatory: {
					maxWidth: 320,
					maxFrameRate: 25,
					minFrameRate: 25
				}
			}
		};

		console.log(name + " registered in room " + room);
		var participant = new Participant(name, self);
		participants[name] = participant;
		var video = participant.video;

		client.handleUserJoined(participant);

		var options = {
			localVideo: video,
			mediaConstraints: constraints,
			onicecandidate: participant.onIceCandidate.bind(participant)
		}

		if(room.search('connection-test') != -1 || room.search('verification-user') != -1 || room.search('rtsp-stream') != -1){
			options.remoteVideo = participant.remoteVideo;

			participant.rtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv(options,
				function (error) {
					if (error) {
						return console.error(error);
					}
					this.generateOffer(participant.offerToReceiveVideo.bind(participant));
				});
		}
		else if(client.settings.video){
			participant.rtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options,
				function (error) {
					if (error) {
						return console.error(error);
					}
					this.generateOffer(participant.offerToReceiveVideo.bind(participant));
				});
		}
		else{
			participant.rtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options,
				function (error) {
					if (error) {
						return console.error(error);
					}
					this.generateOffer(participant.offerToReceiveVideo.bind(participant));
				});
		}

		msg.data.forEach(receiveVideo);
	}

	this.leave = function() {
		this.sendMessage({
			id: 'leaveRoom'
		});

		for (var key in participants) {
			participants[key].dispose();
		}

		ws.close();
	}

	function receiveVideo(sender) {
		var participant = new Participant(sender, self);
		client.handleUserJoined(participant);
		participants[sender] = participant;
		var video = participant.video;

		var options = {
			remoteVideo: video,
			onicecandidate: participant.onIceCandidate.bind(participant)
		}

		participant.rtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options,
			function (error) {
				if (error) {
					return console.error(error);
				}
				this.generateOffer(participant.offerToReceiveVideo.bind(participant));
			});
		;
	}

	function onParticipantLeft(request) {
		console.log('Participant ' + request.name + ' left');

		var participant = participants[request.name];
		client.handleUserLeft(participant);
		participant.dispose();
		delete participants[request.name];
	}

	this.sendMessage = function(message) {
		ws.send(JSON.stringify(message));
	}

	this.sendToAll = function(message, toSelf) {
		ws.send(JSON.stringify({id: 'sendToAll', message: message, toSelf: toSelf}));
	}

	this.getParticipant = function(id){
		return participants[id];
	}

	this.getWebSocket = function(){
		return ws;
	}
}
