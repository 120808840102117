function TeleVisiumClient(options){
	console.log("TeleVisiumClient 0.0.3")

	this.settings = {
		webSocket: null,
		onUserJoined: null,
		onUserLeft: null,
		onMessage: null,
		video: false
	};

	function extend(a, b){
		for(var key in b)
			if(b.hasOwnProperty(key))
				a[key] = b[key];
		return a;
	}

	extend(this.settings, options);

	var conferenceRoom = null;

	var timer = null;

	this.join = function(id, room){
		conferenceRoom = new ConferenceRoom(this);
		conferenceRoom.register(id, room);

		timer = setInterval(function (){
			conferenceRoom.sendMessage({id: "ping"});
		}, 5000);
	}

	this.leave = function(){
		conferenceRoom.leave();

		clearInterval(timer)
	}

	this.sendToAll = function(message){
		conferenceRoom.sendToAll(message, true);
	}

	this.sendToOthers = function(message){
		conferenceRoom.sendToAll(message, false);
	}

	this.handleCustomMessage = function(message){
		if(this.settings.onMessage != null)
			this.settings.onMessage(message);
	}

	this.handleUserJoined = function(user){
		if(this.settings.onUserJoined != null)
			this.settings.onUserJoined(user);
	}

	this.handleUserLeft = function(user){
		if(this.settings.onUserLeft != null)
			this.settings.onUserLeft(user);
	}

	this.toggleAudio = function(id, enabled) {
		conferenceRoom.getParticipant(id).rtcPeer.audioEnabled = enabled;

		var stream, streamIndex, trackIndex;
		var streams = conferenceRoom.getParticipant(id).rtcPeer.peerConnection.getLocalStreams();

		for(streamIndex = 0; streamIndex < streams.length; streamIndex++){
			var tracks = stream[streamIndex].getAudioTracks();

			for(trackIndex = 0; trackIndex < tracks.length; trackIndex++)
				tracks[trackIndex].enabled = enabled;
		}
	}

	this.toggleVideo = function(id, enabled) {
		conferenceRoom.getParticipant(id).rtcPeer.videoEnabled = enabled;

		var streamIndex, trackIndex;
		var streams = conferenceRoom.getParticipant(id).rtcPeer.peerConnection.getLocalStreams();

		for(streamIndex = 0; streamIndex < streams.length; streamIndex++){
			var tracks = streams[streamIndex].getVideoTracks();

			for(trackIndex = 0; trackIndex < tracks.length; trackIndex++)
				tracks[trackIndex].enabled = enabled;
		}
	}

	this.getFrame = function(id) {
		try {
			var video = conferenceRoom.getParticipant(id).video;

			var canvas = document.createElement('canvas');
			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;
			canvas.getContext('2d').drawImage(video, 0, 0);

			return canvas;
		}
		catch(err) {
			return null;
		}
	}

	this.getConferenceRoom = function() {
		return conferenceRoom;
	}
}
